import { useEffect, useState } from "react";
import { dataProvider } from "src/libs";
import {
  BaseRecords,
  ErrorResponse,
  GetOneResponse,
} from "src/libs/dataProvider/types";

import { IUseOneProps, IUseOneReturnValue } from "./type";

export function useOne<TData extends BaseRecords = BaseRecords>({
  apiUrl,
  resource,
  id,
  meta,
  enabled = true, // Add this new prop with a default value of true
}: IUseOneProps & { enabled?: boolean }): IUseOneReturnValue<TData> {
  const { getOne } = dataProvider(apiUrl);
  const [data, setData] = useState<GetOneResponse<TData> | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const fetchOneData = async () => {
    if (!enabled || !id) return; // Don't fetch if not enabled or id is not available
    setIsLoading(true);
    try {
      const getOneData = await getOne<TData>({
        resource,
        id,
        meta,
      });
      const fetchError = Boolean((getOneData.data as ErrorResponse).error);
      setIsSuccess(!fetchError);
      setIsError(fetchError);
      setData(getOneData);
    } catch (error) {
      setIsError(true);
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOneData();
  }, [enabled, id, refetch]);

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch: () => setRefetch(!refetch),
  };
}